
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("提现新增", ["提现管理;remove_balance_records"])
    })

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    });

    const formRef = ref<FormInstance>()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const remove_balance_record = ref({
      balance: 0,
      user_name: '',
      user_mobile: '',
      note: ""
    })

    const onCancel = () => {
      router.push({ name: "remove_balance_records" })
    }

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return

      formEl.validate((valid) => {
        if (valid) {
          let params = {
            remove_balance_record: {
              ...remove_balance_record.value
            }
          }

          store
            .dispatch(
              Actions.CREATE_REMOVE_BALANCE_RECORD,
              params
            )
            .then(() => {
              router.push({ name: "remove_balance_records" })
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "确认",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              })
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
        } else {
          return false
        }
      })
    }

    return {
      onSubmit,
      remove_balance_record,
      rules,
      onCancel,
      formRef,
    }
  },
})
